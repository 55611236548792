module.exports = {
  comm: {
    home: 'Home',
    pricing: 'Prezzi',
    blog: 'Clonazione vocale e API', // 更新
    language: 'Lingua:',
    select_language: 'Seleziona lingua',
    txt2voice: 'Testo in voce',
    voice2txt: 'Voce in testo',
    voiceclone: 'Clonazione di voce',
    video2txt: 'Video in testo',
    footer_help: 'Hai bisogno di aiuto? Scrivici:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'Accedi',
    login_desc: 'Accedi per accedere al tuo account TikTok Voice Generator',
    logout: 'Esci',
  },
  txt2voice: {
    title: 'Generatore di voce TikTok: Generare voci AI TikTok divertenti',
    description: 'Genera e scarica voci AI TikTok divertenti gratuitamente, come la voce di Jessie, la voce di C3PO, la voce di Ghostface...',
    keywords: 'Generatore di voce TikTok, Voce AI TikTok, Generatore di voce Tik Tok',
    main_title_p01: 'Generatore di voce TikTok',
    main_title_p02: 'Genera voci TikTok divertenti: voce di Jessie, voce di C3PO, voce di Ghostface',
    main_textarea_holder: 'Digita o incolla il testo qui',
    main_genvoice: 'genera',
    main_generating: 'generando',
    main_input_empty: 'per favore digita o incolla il testo',
    daily_usage_limit_msg: '1) Hai raggiunto il limite massimo di utilizzo giornaliero. Per favore, torna domani. 2) Se hai bisogno urgentemente di più utilizzi, per favore inviaci una e-mail.',
    text_max_prompt1: 'Per favore limita il numero di parole a',
    text_max_prompt2: 'parole o meno!',
    popup_nologin_title: 'Migliora il tuo piano di abbonamento',
    popup_nologin_desc: 'Sblocca più tempo di generazione vocale passando a un abbonamento premium.',
    popup_nologin_btntext: 'Esplora i piani di abbonamento',
    popup_nosub_title: 'Migliora il tuo piano di abbonamento',
    popup_nosub_desc: 'Sblocca più tempo di generazione vocale passando a un abbonamento premium.',
    popup_nosub_btntext: 'Esplora i piani di abbonamento',
    popup_sublimit_title: 'Abbonamento esaurito, per favore aggiorna',
    popup_sublimit_desc: 'Migliora la tua esperienza con le nostre funzionalità premium e accesso illimitato.',
    popup_sublimit_btntext: 'Vai ad acquistare di più',
  },
  pricing: {
    new_features_alert: "📣 Abbiamo lanciato i servizi di 🎙️ Clonazione Vocale e 🤖 API per i membri a pagamento! (Dopo il pagamento, invia un'email a tiktokaivoicetool@gmail.com per assistenza su queste funzionalità). Inoltre, non esitare a contattarci via email per qualsiasi richiesta di personalizzazione😃", // 新增
    pricing_title: 'Dai creatori individuali alle grandi aziende, c\'è un piano per te.',
    pricing_desc: 'Oltre 100 voci naturali e umane. Oltre 15 lingue. Il prezzo più basso per quasi tutti',
    sub_free_name: 'Gratuito',
    sub_free_desc: 'Per individui che vogliono provare l\'audio AI all\'avanguardia',
    sub_free_content: [
      'Limite di 1000 caratteri al mese',
      '5 generazioni al giorno',
    ],
    sub_starter_name: 'Starter',
    sub_starter_desc: 'Per creatori che producono contenuti premium per un pubblico globale',
    sub_starter_content: [
      '1 clone vocale',
      'Limite di 300.000 caratteri al mese (circa 7 ore di audio)',
      'Download illimitati',
      'Supporto email entro 72 ore',
      'Accesso prioritario a nuove voci e funzionalità',
    ],
    sub_pro_name: 'Pro',
    sub_pro_desc: 'Per creatori che stanno espandendo la loro produzione di contenuti',
    sub_pro_content: [
      'Accesso API',
      '3 cloni vocali',
      'Limite di 1.000.000 di caratteri al mese (circa 24 ore di audio)',
      'Download illimitati',
      'Supporto email entro 48 ore',
      'Accesso prioritario a nuove voci e funzionalità',
    ],
    period_year: 'Annuale',
    period_month: 'Mensile',
    period_month_short: 'mese',
    billed_year: 'Fatturato annualmente',
    most_popular: 'Più popolare',
    discount_quantity: 'Risparmia $48',
    buy_btntext: 'Inizia',
    pay_succ: 'Pagamento riuscito!',
    pay_succ_desc: 'Grazie per il tuo acquisto. La transazione è stata elaborata con successo.',
    pay_succ_btntext: 'Inizia a usare TikTok Voice',
    pay_fail: 'Pagamento fallito!',
    pay_fail_desc: "Siamo spiacenti, non siamo riusciti a elaborare la tua transazione. Per favore riprova o contatta il nostro team di supporto (tiktokaivoicetool@gmail.com).",
    pay_fail_btntext: 'Torna alla home page',
  },
  setting: {
    setting: 'Impostazioni',
    setting_title: 'Gestisci qui il tuo account, utilizzo e abbonamento.',
    basic_info: 'Informazioni di base', // 新增，放在 setting_title 下方
    user_name: 'Nome',
    user_email: 'Email',
    user_account: 'Account',
    user_subscript: 'Piano attuale',
    user_usage: 'Utilizzo',
    manage_subscript: 'Gestisci abbonamento',
    use_limit_promote: "Hai utilizzato 5000 caratteri del tuo limite mensile di 10000 caratteri.",
    not_logged_in: 'Non hai effettuato l\'accesso', // 新增
    go_to_login: 'Vai al login >>', // 新增
  },
  login: { // 新增 login 对象，放在 setting 下方
    title: "Generatore di Voci TikTok",
    subtitle: "Accedi per accedere al tuo account TikTok Voice Generator",
    alreadyLoggedIn: "Hai già effettuato l'accesso",
    goToHome: "Vai alla home page"
  },
  faq: {
    quest1: 'Generatore di voce TikTok - Miglior strumento di voce AI TikTok',
    answer1_1: 'È uno strumento generatore di voce AI TikTok sviluppato dalla più recente tecnologia tts TikTok, può generare voci TikTok come la voce di Jessie (voce femminile), la voce di Siri, la voce di Ghostface, la voce di C3PO (voce robotica), la voce profonda (narratore), la voce di Skye, la voce calda, la voce di Bestie, la voce di Eroe (voce di Chiris), la voce empatica, la voce seria, la voce di Joey, la voce di Stitch, la voce di Stormtrooper (Star Wars), la voce di Rocket (Guardians of the Galaxy).',
    answer1_2: 'Le voci AI TikTok che saranno supportate a breve includono: la voce di Derek, la voce di Trickster, la voce di Austin Butler, la voce di giornalista, la voce di Adam, la voce di cane, la voce di Miley Cyrus, la voce di Alexa, la voce di alieno, la voce di animale, la voce di bambino, la voce di computer, la voce di chipmunk, la voce di eco, la voce di NPR.',
    answer1_3: 'Inoltre, lo strumento generatore di voce TikTok supporta molte altre lingue, tra cui cinese, giapponese, coreano, vietnamita, tailandese, hindi, persiano, russo, tedesco, francese, rumeno, ceco, spagnolo, portoghese, bengalese, italiano, arabo, urdu, cinese tradizionale e malese.',
    answer1_4: "Se hai bisogno urgente di una voce specifica, per favore inviami un'email.",

quest2: 'Quali sono i vantaggi del generatore di voce TikTok?',
    answer2_1: '- Lo strumento generatore di voce TikTok può generare vari tipi di voci, che sono spesso utilizzate nei video TikTok.',
    answer2_2: "- È l'ultimo generatore di voce AI che può generare voci tts TikTok simili a quelle umane.",
    answer2_3: "- È più conveniente per l'editing video su un PC.",
    answer2_4: "- Puoi usare alcune voci che attualmente non si trovano nell'app tts TikTok.",

quest3: 'Come usare il generatore di voce TikTok?',
    answer3_1: 'Il generatore di voce TikTok è molto facile da usare:',
    answer3_2: "- Seleziona la lingua e l'accento della voce.",
    answer3_3: '- Digita il testo che deve essere convertito in voce nella casella di inserimento.',
    answer3_4: '- Premi il pulsante genera e aspetta qualche secondo.',
    answer3_5: '- Riproduci o scarica la voce AI TikTok.',

quest4: 'Quali sono le voci TikTok più famose e divertenti?',
    answer4_1: 'La voce maschile più famosa di TikTok è la voce profonda, ufficialmente chiamata narratore.',
    answer4_2: "Per la voce TikTok più divertente, consiglio personalmente la voce di Ghostface e la voce di C3PO. Queste sono spesso utilizzate per il doppiaggio di video TikTok divertenti perché sono facilmente riconoscibili e possono rapidamente catturare l'attenzione degli spettatori.",

quest5: 'Quali sono le voci TikTok più fastidiose?',
    answer5_1: 'Credo che non ci sia davvero una voce TikTok "fastidiosa".',
    answer5_2: "Dipende più dal contesto e dal contenuto in cui viene utilizzata la voce, che può rendere alcune persone a disagio. Ad esempio, alcune persone possono trovare la voce di Jessie fastidiosa perché è apparsa troppo frequentemente su TikTok in un certo periodo. Tuttavia, a causa del suo tono e qualità, è in realtà un effetto di voce TikTok fantastico. Inoltre, l'attrice di voce TikTok dietro di essa è molto professionale e pubblica anche i suoi video su TikTok.",
    answer5_3: "Pertanto, suggerisco a tutti di mettere da parte le preoccupazioni e i pregiudizi, ascoltare più volte le voci di questo strumento e trovare pazientemente l'effetto di voce TikTok che meglio si adatta a te.",

quest6: 'Come usare il generatore di voce TikTok per generare la voce di Jessie?',
    answer6_1: '- Nel primo menu a tendina dello strumento generatore di voce TikTok, seleziona English (US), poi nel secondo menu a tendina, seleziona la voce di Jessie (voce femminile).',
    answer6_2: '- Digita il testo nella casella di inserimento e clicca sul pulsante genera.',
    answer6_3: '- Aspetta qualche secondo o fino a dieci secondi, ascolterai la voce AI. Nella barra degli strumenti, trova il pulsante con una freccia verso il basso e clicca per scaricare.',

quest7: 'Come usare il generatore di voce TikTok per generare la voce di C3PO?',
    answer7_1: '- Nel primo menu a tendina dello strumento generatore di voce TikTok, seleziona English (US), poi nel secondo menu a tendina, seleziona la voce di C3PO (voce robotica).',
    answer7_2: '- Digita il testo nella casella di inserimento e clicca sul pulsante genera.',
    answer7_3: '- Aspetta qualche secondo o fino a dieci secondi, ascolterai la voce AI. Nella barra degli strumenti, trova il pulsante con una freccia verso il basso e clicca per scaricare.',

quest8: 'Come usare il generatore di voce TikTok per generare la voce di Ghostface?',
    answer8_1: '- Nel primo menu a tendina dello strumento generatore di voce TikTok, seleziona English (US), poi nel secondo menu a tendina, seleziona la voce di Ghostface.',
    answer8_2: '- Digita il testo nella casella di inserimento e clicca sul pulsante genera.',
    answer8_3: '- Aspetta qualche secondo o fino a dieci secondi, ascolterai la voce AI. Nella barra degli strumenti, trova il pulsante con una freccia verso il basso e clicca per scaricare.',

quest9: 'Come usare il generatore di voce TikTok per generare la voce di Siri?',
    answer9_1: '- Nel primo menu a tendina dello strumento generatore di voce TikTok, seleziona English (US), poi nel secondo menu a tendina, seleziona la voce di Siri.',
    answer9_2: '- Digita il testo nella casella di inserimento e clicca sul pulsante genera.',
    answer9_3: '- Aspetta qualche secondo o fino a dieci secondi, ascolterai la voce AI. Nella barra degli strumenti, trova il pulsante con una freccia verso il basso e clicca per scaricare.',

quest10: "Come aggiungere l'effetto di voce TikTok a un video TikTok?",
    answer10_1: 'Se vuoi sapere come utilizzare le voci ufficiali di TikTok su TikTok, presto scriverò un post sul blog dedicato a questo argomento.',
    answer10_2: 'Qui, spiegherò come pubblicare la tua voce su TikTok dopo averla generata e scaricata dal generatore di voce TikTok.',
    answer10_3: '1. Se hai generato la voce TikTok sul tuo PC e vuoi caricarla su TikTok o su qualsiasi altra app di editing video sul tuo telefono, devi trasferire il file vocale sul tuo telefono. Per gli iPhone, puoi utilizzare airDrop per il trasferimento. Per i telefoni Android, non sono molto familiare con questo processo, ma sicuramente troverai metodi e strumenti per farlo.',
    answer10_4: '2. Una volta che il file vocale è stato trasferito sul tuo telefono, puoi aprire TikTok:',
    answer10_5: "- Tocca il pulsante "+" nella parte inferiore dell'interfaccia, seleziona il video sul tuo telefono.",
    answer10_6: "- Dopo aver caricato il video, trova un'icona quadrata sul lato destro dello schermo per entrare nella pagina di modifica.",
    answer10_7: '- Nella parte inferiore della pagina, trova il pulsante "aggiungi voce" e tocca per selezionare il file vocale appena trasferito.',
    answer10_8: "- In questa interfaccia, puoi fare alcune regolazioni al video, poi tocca il pulsante nell'angolo in alto a destra per pubblicarlo.",

quest11: 'Il TikTok Voice è gratuito?',
    answer11: 'Sì, è uno strumento gratuito di generazione di voce AI TikTok da testo a voce.',

quest12: 'Dove posso generare una voce TikTok popolare nel mio video TikTok?',
    answer12: 'Puoi visitare https://tiktokvoice.net/ per aggiungere voci AI TikTok al tuo video.'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}